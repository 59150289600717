import React, { Component } from "react";
import Propstypes from "prop-types";
import axios from 'axios';
import consts from "../../utils/constants"

import Aside from "./Aside";
import Main from "./Main";
import ReactPaginate from "react-paginate";

export class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            elements: [],
            perPage: 10,
            currentPage: 0,
            currentNew: {},
            newId: 0,
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            newId: this.props.newId,
        })
        this.receiveData();
    }

    receiveData() {
        this.setState({
            data: this.props.asideData,
            pageCount: Math.ceil(this.props.asideData.total / this.state.perPage)
        }, () => this.setElementsForCurrentPage());
    }

    setElementsForCurrentPage() {
        axios.get(consts.getNewsUrl(this.state.currentPage + 1))
            .then(resp => {
                this.setState({
                    ...this.state,
                    elements: resp.data.data.posts.data,
                })

                if (this.state.newId > 0)
                    axios.get(consts.NEW_URL + "/" + this.state.newId)
                        .then(resp =>
                            this.setState({
                                currentNew: resp.data.data.post,
                                isLoading: false
                            })
                        )
                else
                    this.setState({
                        currentNew: this.state.elements[0],
                        isLoading: false
                    })
            })
    }

    handlePageClick = (data) => {
        window.scrollTo(0, 0);
        const selectedPage = data.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({ currentPage: selectedPage, offset: offset, newId: 0, isLoading: true }, () => {
            this.setElementsForCurrentPage();
        });
    }

    handleAsideClick = (data) => {
        window.scrollTo(0, 0);
        this.setState({ currentNew: data })
    }

    render() {

        return (
            this.state.isLoading ?
                <div className="loader"></div>
                :
                <React.Fragment>
                    <section className="news-container">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <Aside
                                        asideData={this.state.elements}
                                        handleAsideClick={this.handleAsideClick}
                                    />
                                    {this.state.pageCount > 1 &&
                                        <ReactPaginate
                                            previousLabel={" < "}
                                            nextLabel={" > "}
                                            breakLabel={<span className="gap">...</span>}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={1}
                                            pageCount={this.state.pageCount}
                                            onPageChange={this.handlePageClick}
                                            forcePage={this.state.currentPage}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"previous_page"}
                                            nextLinkClassName={"next_page"}
                                            disabledClassName={"disabled"}
                                            activeClassName={"active"}
                                        />
                                    }
                                </div>
                                <div className="col-lg-8">
                                    <Main new={this.state.currentNew} />
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
        );
    }
}
Container.Propstypes = {
    asideData: Propstypes.array,
}
Container.defaultProps = {
    asideData: []
}
export default Container;
