import React, { useState, useEffect } from "react";
import axios from "axios";
import consts from "../utils/constants";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

//Components List
import NavBar from "../components/NavBar";
import Banner from "../components/Banner";
import Services from "../components/Services";
import Feadback from "../components/Feadback";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import Section from "../components/Section";
import HolidayAlert from "../components/HolidayAlert/HolidayAlert";
import IconOffline from "../assets/img/icon_offline.png";

const UniaoSeguradora = () => {
  const [state, setState] = useState({
    address: "",
    complement: "",
    district: "",
    cep: "",
    city: "",
    state: "",
    email: "",
    phone: "",
    banners: [],
    news: [],
    isLoading: true,
  });
  const [disponibility, setDisponibility] = useState({
    status: "",
    text: "",
    script: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    axios.get(consts.getSitePageUrl()).then((resp) =>
      setState({
        ...state,
        address: resp.data.data.site.address,
        complement: resp.data.data.site.complement,
        district: resp.data.data.site.district,
        cep: resp.data.data.site.cep,
        city: resp.data.data.site.city,
        state: resp.data.data.site.state,
        email: resp.data.data.site.email,
        phone: resp.data.data.site.phone_number,
        banners: resp.data.data.site.banners,
        news: resp.data.data.site.posts,
        isLoading: false,
      })
    );
  }, []);

  useEffect(() => {
    if (disponibility.status === "online") {
      var scriptString = disponibility.script;
      const partes = scriptString.match(/"(.*?)"|'(.*?)'/g);

      if (partes) {
        const src = partes[0].replace(/"|'/g, "");
        const token = partes[1].replace(/"|'/g, "");
        const script = document.createElement("script");
        script.src = src;
        script.setAttribute("token", token);
        script.async = true;
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [disponibility]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    axios
      .post(consts.getApiChatBotUrl(), { site: 3 })
      .then((resp) => {
        setDisponibility({
          status: "online",
          script: resp.data,
        });
      })
      .catch((error) => {
        if (error.response == null) {
          return;
        } else {
          setDisponibility({
            status: "offline",
            text: error.response.data.unavailability_message,
            script: "",
            image: error.response.data.image,
          });
        }
      });
  }, []);

  return (
    <div className="uniao-seguradora-page">
      {state.isLoading ? (
        <div className="loader"></div>
      ) : (
        <React.Fragment>
          <NavBar
            site={consts.APP_NAME_UNIAO_SEGURADORA}
            logo={consts.LOGO_UNIAO_SEGURADORA}
            logoNegativo={consts.LOGO_NEGATIVO_UNIAO_SEGURADORA}
          />

          <Banner homeslidesData={state.banners} />

          <Section />
          <Services
            sectionTitle="Nossos"
            sectionTitleSpan="Produtos"
            productsPageUrl="/produtos"
            servicesData={[
              {
                icon: require("../assets/img/prod-peculio.png"),
                key: "peculio#assistencia-financeira",
                title: "Pecúlio",
                content:
                  "A UNIÃO Seguradora S.A. - Vida e Previdência possui convênio com órgãos públicos, entidades associativas e empresas privadas para conceder, através do desconto em folha de pagamento, uma Assistência Financeira emergencial.",
              },
              {
                icon: require("../assets/img/prod-seguro.png"),
                key: "seguro#apc-acidentes-pessoais-coletivos",
                title: "Seguro",
                content:
                  "O seguro de acidentes pessoais cobre morte e invalidez permanente (total ou parcial) e outros riscos causados - unicamente - por acidente.",
              },
              {
                icon: require("../assets/img/prod-assistencia.png"),
                key: "assistencias#sobre",
                title: "Assistências",
                content:
                  "Nossos seguros vão além! Eles possuem serviços que ajudam no seu dia a dia. Telemedicina, Assistência Pet, Assistência Residencial e muito mais…",
              },
            ]}
          />
          <Feadback
            sectionTitleSpan="Notícias"
            buttonText="Mais Notícias"
            newsPageUrl="/noticias"
            feadbacksData={state.news}
          />
          <ContactUs page={"uniao-seguradora"} />
          <Footer companyName={consts.APP_NAME_UNIAO_SEGURADORA} />
          <ScrollUpBtn />
          <HolidayAlert />
          {disponibility.status === "offline" && disponibility.text != null && (
            <div className="schedule_config">
              <Button onClick={handlePopoverOpen}>
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={disponibility.image ? disponibility.image : IconOffline}
                  alt=""
                />
              </Button>
              <Popover
                anchorEl={anchorEl}
                open={open}
                id={open ? "simple-popover" : undefined}
                style={{ width: "40%" }}
                onClose={handlePopoverClose}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "center",
                }}
              >
                <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{disponibility.text}</div>
              </Popover>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default UniaoSeguradora;
